<template>
  <header id="header" class="fixed-top border-bottom bg-main shadow-sm">
    <div class="container-fluid shadow-sm">
      <nav class="navbar navbar-expand-md navbar-dark px-0 pr-sm-3">
        <a
          @click="back"
          class="btn p-0 mr-md-4 text-light h5 mb-0"
        >
          <i class="fas fa-arrow-left"></i>
        </a>
        <span
          class="
            navbar-brand
            bg-transparent
            rounded
            p-0
            text-center
            mr-0 mr-md-5
          "
        >
          <span class="h6 font-weight-bold text-truncate">Pelanggan Info</span>
        </span>
        <div class="text-nowrap order-md-last">
          <!-- <a class="btn px-0 mr-3 d-none">
            <i class="fa fa-comments-o text-light"></i>
          </a> -->
          <router-link :to="'/jual/pelanggan/edit/'+state.id" class="btn px-0 mr-3 d-inline-block">
            <i class="fas fa-pencil-alt text-light"></i>
          </router-link>
          <a @click="deleteData()" class="btn px-0">
            <i class="fa fa-trash text-light"></i>
          </a>
        </div>
      </nav>
    </div>
    <!-- .container -->
  </header>

  <div class="container-fluid mt-header">
    <div class="row" id="body-sidemenu" v-if="state.finish">
      <!-- MAIN -->
      <div id="main-content" class="col pb-5 with-fixed-sidebar">
        <div
          class="
            jumbotron jumbotron-fluid
            mb-0
            shadow-sm
            no-side-margin
            bg-secondary
          "
        >
          <div class="container text-center">
            <p class="h5 text-white">{{ state.data.prefix }} {{ state.data.name }}</p>
            <p class="h6 mb-0 text-white">{{ state.data.group.name }}, {{ state.data.blok_no }} {{ state.data.alamat }}</p>
          </div>
        </div>

        <div class="row mt-3 text-center">
          <div class="col-12 col-md-4">
            <p class="mb-1 small text-muted">Telepon</p>
            <p class="h6 mb-0">
              <a href="tel:+6281234567890">{{ state.data.phone }}</a>
            </p>
          </div>
          <!-- .col -->
        </div>
        <!-- .row -->

        <hr class="no-side-margin" />

        <div class="row mt-3">
          <div class="col-6">
            <p class="mb-1 small text-muted">Grup</p>
            <p class="mb-0">{{ state.data.group.name }}</p>
          </div>
          <!-- .col -->
          <div class="col-6 text-right text-md-left">
            <p class="mb-1 small text-muted">Email</p>
            <p class="mb-0">
              <a :href="'mailto:'+state.data.email">{{ state.data.email? state.data.email: '-' }}</a>
            </p>
          </div>
          <!-- .col -->
        </div>
        <!-- .row -->

        <div class="row mt-3">
          <div class="col-6">
            <p class="mb-1 small text-muted">Alamat</p>
            <p class="mb-0">{{ state.data.alamat }}</p>
          </div>
          <!-- .col -->
          <div class="col-6 text-right text-md-left">
            <p class="mb-1 small text-muted">Blok-Nomor</p>
            <p class="mb-0">{{ state.data.blok_no }}</p>
          </div>
          <!-- .col -->
        </div>
        <!-- .row -->

        <div class="row mb-3">
          <div class="col-12 px-0">
            <ul class="list-group rounded-0 mt-3">
              <li
                class="
                  list-group-item
                  d-flex
                  justify-content-between
                  align-items-center
                  border-left-0 border-right-0
                  p-0
                "
              >
                <router-link
                  :to="'/jual/pelanggan/edit/'+state.data.id"
                  class="py-2 pl-3 text-uppercase stretched-link"
                  >Edit</router-link>
                <i class="fa fa-caret-right mr-3"></i>
              </li>
              <li
                class="
                  list-group-item
                  d-flex
                  justify-content-between
                  align-items-center
                  border-left-0 border-right-0
                  p-0
                "
              >
                <a href="" class="py-2 pl-3 text-uppercase stretched-link"
                  >Riwayat</a
                >
                <i class="fa fa-caret-right mr-3"></i>
              </li>
              <li
                class="
                  list-group-item
                  d-none
                  justify-content-between
                  align-items-center
                  border-left-0 border-right-0
                  p-0
                "
              >
                <a href="" class="py-2 pl-3 text-uppercase stretched-link"
                  >Chat</a
                >
                <i class="fa fa-caret-right mr-3"></i>
              </li>
            </ul>
          </div>
          <!-- .col -->
        </div>
        <!-- .row -->

        <form @submit.prevent="searchData">
            <div class="input-group input-group-sm my-3">
                <div class="input-group mb-3">
                <input type="text" class="form-control" placeholder="Cari..."
                v-model="state.search" />
                <span class="input-group-append">
                    <button type="submit" class="btn btn-outline-main"><i class="fa fa-search"></i
                ></button>
                </span>
                </div>
            </div>
        </form>

        <div class="small text-truncate">Daftar Pesanan Aktif {{ state.data.prefix }} {{ state.data.name }}:</div>

        <div id="resultList" class="row mt-3 mb-5" v-if="state.pesananExist">
          <div class="col-12 col-md-6 col-lg-4 col-xl-3" v-for="(pes,index) in state.pesanan" :key="index">
            <div class="card mb-2">
              <div class="card-body p-0">
                <div class="float-left w-25 border-right">
                  <div
                    class="cropped"
                    :style="'background-image: url('+state.url + pes.photo +');'"
                  ></div>
                </div>
                <div class="float-right w-75 p-2 pl-3">
                  <a
                    href=""
                    class="
                      card-title
                      text-main
                      font-weight-bold
                      d-block
                      mb-1
                      mt-1
                      card-link
                      stretched-link
                      text-truncate
                    "
                    >{{ pes.name }}</a
                  >
                  <p class="card-text mb-1 text-truncate">{{ pes.jumlah }} pcs</p>
                  <p class="card-text mb-0 text-truncate">
                    <span class="currency">Rp. {{ changeMoney(pes.total) }}</span>
                  </p>
                </div>
                <div class="clearfix"></div>
              </div>
            </div>
          </div>
          <!-- .col -->
        </div>
        <!-- .row -->

        <router-link
            to="/jual/pesanan/tambah"
            id="btn-float"
            class="
              bg-warning
              position-fixed
              rounded-circle
              shadow
              text-dark text-center
            "
            >
            <div class="d-flex justify-content-center mt-3">
            <i class="fas fa-cart-plus" style="font-size: 25px;"></i>
            </div>
        </router-link>
      </div>
      <!-- main-content -->
    </div>
    <!-- .row -->
  </div>
  <!-- .container -->
</template>
<script>
import { reactive, onMounted } from 'vue';
import axios from 'axios';
import { useRoute, useRouter } from 'vue-router';
import { useVueSweetAlert2 } from "../../../useVueSweetAlert2.js";

export default {
  setup() {
    const route = useRoute();
    const router = useRouter();
    const $swal = useVueSweetAlert2();
    const state = reactive({
      data: {},
      pesanan: {},
      originalPesanan: {},
      finish: false,
      id: '',
      pesananExist: false,
      url: process.env.VUE_APP_API_URL,
      search: '',
    })
    const loadData = async () => {
      state.finish = false;
      let id = route.params.id;
      await axios.get('/api/seller/pelanggan/'+id).then((res) => {
        if(res.data.success){
          state.data = res.data.data;
          state.finish = true;
        }
      })
    }
    const loadPesanan = async () => {
      state.finish = false;
      let id = route.params.id;
      await axios.get('/api/seller/pelanggan/getPesanan/'+id).then((res) => {
        if(res.data.success){
          state.pesanan         = res.data.data;
          state.originalPesanan = res.data.data;
          if(res.data.exist){
            state.pesananExist = true;
          }
        }
      })
    }
    onMounted(() => {
      state.id = route.params.id;
      loadData();
      loadPesanan();
    });
    const changeMoney = (dt) => {
        let cur = Math.ceil(Number(dt));
        let mo = (cur).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        let ret = mo.split(".")[0]; 
        return ret; 
    }
    const searchData = () => {
        let result = [];
        const data = state.originalPesanan;
        Object.values(data).find((dt, index) => { 
          if(dt.name.match(state.search)){ 
            result.push(data[index]);
          }
        });
        state.pesanan = result; 
    }
    const back = () => {
      window.history.back();
    }
    const deleteData = () => {
      if(state.id !== ''){
        $swal
        .fire({
          text: "Apakah Anda yakin Akan Menghapus Pelanggan?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: "Ya",
          cancelButtonText: "Tidak",
          confirmButtonColor: '#b50b1b',
        })
        .then((result) => {
          if (result.isConfirmed) {
            axios.delete('/api/seller/pelanggan/'+state.id).then((res) => {
              if(res.data.success){
                $swal.fire({
                  icon: "success",
                  title: "Info",
                  text: "Pelanggan Berhasil Di Hapus!",
                });
                router.push({ path: "/jual/pelanggan/" });
              }else {
                $swal.fire({
                  icon: "warning",
                  title: "Info",
                  text: res.data.messages,
                });
              }
            });
          }
        });
      }
    }
    return {
      state,
      changeMoney,
      searchData,
      back,
      deleteData
    }
  },
}
</script>